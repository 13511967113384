import React from 'react';
import Grid from '../components/grid';
import Hero from '../components/hero';
import Map from '../components/map';
import Multimedia from '../components/multimedia';
import mockData from '../utils/mockData'
import Text from '../components/ui/text'
import * as styles from '../styles/components.module.scss'
import { Accordion } from 'react-bootstrap';

const Components = () => {
  // TODO: add new components [lineargrid, masonrygrid, imap]
  const renderText = (text, withMargin) => {
    return  <Text as="h2" type="h2" className={withMargin && styles.margin}>
              {text}
            </Text>
  }
  return (
    <>
      {renderText("HERO", true)}
      <Hero {...mockData.hero}/>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header> {renderText("HEADLINES")}</Accordion.Header>
          <Accordion.Body>
            <Text as="h2" type="h1">H1 Headline</Text>
            <Text as="h2" type="h1" variant="light">H1 Headline LIGHT</Text>
            <Text as="h2" type="h1" variant="bold">H1 Headline BOLD</Text>
            <Text as="h2" type="h2">H2 Headline</Text>
            <Text as="h2" type="h2" variant="light">H2 Headline LIGHT</Text>
            <Text as="h2" type="h2" variant="bold">H2 Headline BOLD</Text>
            <Text as="h2" type="h3">H3 Headline</Text>
            <Text as="h2" type="h3" variant="light">H3 Headline LIGHT</Text>
            <Text as="h2" type="h3" variant="bold">H3 Headline BOLD</Text>
            <Text as="h2" type="h4">H4 Headline</Text>
            <Text as="h2" type="h4" variant="light">H4 Headline LIGHT</Text>
            <Text as="h2" type="h4" variant="bold">H4 Headline BOLD</Text>
            <Text as="h2" type="h5">H5 Headline</Text>
            <Text as="h2" type="h5" variant="light">H5 Headline LIGHT</Text>
            <Text as="h2" type="h5" variant="bold">H5 Headline BOLD</Text>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>{renderText("TEASER WITH ICON")}</Accordion.Header>
          <Accordion.Body>
            <Grid {...mockData.teaserWithIconGrid}/>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>{renderText("MULTIMEDIA 1 IMAGE")}</Accordion.Header>
          <Accordion.Body>
            <Multimedia {...mockData.multimedia}/>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>{renderText("MULTIMEDIA MULTIPLE IMAGES")}</Accordion.Header>
          <Accordion.Body>
            <Multimedia {...mockData.multimedia2}/>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="4">
          <Accordion.Header>{renderText("TEXT VISUAL")}</Accordion.Header>
          <Accordion.Body>
            <Grid {...mockData.gridTeaserWithImage}/>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="5">
          <Accordion.Header>{renderText("TEXT VISUAL RIGHT")}</Accordion.Header>
          <Accordion.Body>
            <Grid {...mockData.gridTeaserWithImageRight}/>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="6">
          <Accordion.Header>{renderText("TEXT VISUAL BOTTOM")}</Accordion.Header>
          <Accordion.Body>
            <Grid {...mockData.gridTeaserWithImageBottom}/>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="7">
          <Accordion.Header>{renderText("TESTIMONIAL")}</Accordion.Header>
          <Accordion.Body>
            <Grid {...mockData.testimonialGrid}/>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      {renderText("MAP", true)}
      <Map {...mockData.map}/>
    </>
  )
}

export default Components;